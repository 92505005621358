@import url('https://fonts.googleapis.com/css2?family=Tiro+Telugu&display=swap');

.home {
  height: 100%;
  background-color: rgba(255, 192, 203, 0.2);
}

.home-contain {
  height: 100%;
  background: url('../images/gdibackground2.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 20px;
  background-color: rgba(255, 192, 203, 0.2);
}

.home-section {
  margin-bottom: 30px;
  padding: 10px 60px;
  background-color: rgba(255, 192, 203, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-section i {
  color: white;
  font-size: 3rem;
}

.home__heading {
  margin-top: auto;
  margin-bottom: auto;
  font-family: 'Tiro Telugu', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  color: #FFFFFF;
}

.home__list {
  list-style-type: none; 
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem; 
} 