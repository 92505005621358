@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap');

.item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #45B3B2 0%, #177997 100%);
  box-shadow: inset 0px -2px 2px 1px #107283;
  border-radius: 20px;
  width: 150px;
  height: 150px;
  margin: auto;
  /* left: 20px; */
  /* top: 160px; */

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.item-link {
  text-decoration: none;
}

.item-title {
  width: 100px;
  letter-spacing: 1px;
  font-family: 'Nunito', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none;
  color: #FFFFFF;
}