.video-responsive {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
}

.video-responsive iframe {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: absolute;
}