@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap');

.feeling-div {
    background-color: #ee8293;
    height: 100vh;
}

.feeling-container {
    background-image: url("../images/bg-grad.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.feeling-h1 {
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: white;
    font-family: 'Nunito', 'sans-serif';
    font-size: 2rem;
}

.feeling-h2 {
    text-align: center;
    color: white;
    font-family: 'Nunito', 'sans-serif';
    font-size: 1.8rem;
}

.feeling-h3 {
    text-align: center;
    color: #0a7a98;
    font-family: 'Nunito', 'sans-serif';
    font-size: 1.2rem;
}

.feeling-section {
    background-color: rgba(255, 255, 255, 0.3);
    margin: 20px;
    padding-top: 20px;
    padding-bottom: 30px;
    border-radius: 10px;
}


.feeling-article {
    display: flex;
    margin: auto 10px;
}

.feeling-face {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 10px;
    padding: 20px 20px;
    width: 100%;
    background-color: #0a7a98;
    border-radius: 10px;
}

.feeling-img {
    width: 50%;
    margin: 10px auto;
}

.feeling-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    border: 2px solid #0a7a98;
    border-radius: 30px;
    background-color: #0a7a98;
    color: white;
    font-size: 1.5rem;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    text-decoration: none;
    transition: 0.4s ease;
}