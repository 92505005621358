.ground {
    background-color: #ee8293;
    height: 100%;
}

.ground-contain {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url("../images/bg-grad.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.ground-h1 {
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: white;
    font-family: 'Nunito', 'sans-serif';
    font-size: 2rem;
}

.ground-h2 {
    text-align: center;
    color: #0a7a98;
    font-family: 'Nunito', 'sans-serif';
    font-size: 1.9rem;
}

.ground-h3 {
    margin: 10px;
    padding-left: 30px;
    color: white;
    font-family: 'Nunito', 'sans-serif';
    font-size: 1.4rem;
}

.ground-section {
    background-color: rgba(255, 255, 255, 0.3);
    margin: 20px;
    padding-top: 20px;
    padding-bottom: 30px;
    border-radius: 10px;
}

.ground-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 50px;
    margin: auto;
    border: 2px solid #0a7a98;
    border-radius: 30px;
    background-color: #0a7a98;
    color: white;
    font-size: 1.5rem;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    text-decoration: none;
    transition: 0.4s ease;
}

.ground-link:hover {
    background-color: white;
    color: #0a7a98;
}