@import url('https://fonts.googleapis.com/css2?family=Tiro+Telugu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap');

.App {
  width: 100%;
  height: 100vh;
  background: url('./images/gdibackground1.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.app-contain {
  height: 100vh;
  margin: auto;
  background-color: rgba(255, 192, 203, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.app-sect-one {
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-img {
  width: 60%;
  margin: auto;
}

.title {
  margin: 5px auto;
  font-size: 4rem;
  font-weight: 400;
  font-family: 'Tiro Telugu', serif;
  color: #FFFFFF;
}

.app-h2 {
  margin-top: -30px;
  font-size: 1.3rem;
  font-family: 'Nunito', 'sans-serif';
  color: white;
}

.app-sect-two {
  width: 100%;
  display: flex;
  justify-content: center;
}

.home__link {
  width: 70%;
  text-decoration: none;
}

.enter-button {
  width: 100%;
  background: linear-gradient(180deg, #45B3B2 0%, #177997 100%);
  box-shadow: inset 0px -2px 2px 1px #107283;
  border: none;
  border-radius: 20px;
  font-family: 'Tiro Telugu', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.5rem;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}
