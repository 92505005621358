.option-container {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin-top: 20px;
}

.option {
  margin: 15px;
}

.option-icon {
  border-radius: 50%;
}

.option-title {
  color: white;
  font-weight: 600;
  margin-top: 3px;
}